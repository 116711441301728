<template>
  <UFormGroup
    label="Default (login) email"
    required
    :error="emailError"
  >
    <div class="relative">
      <UInput
        v-model="email"
        @blur="validateEmail"
        @input="debounceCheckEmail"
        :trailing-icon="getTrailingIcon"
        :color="inputColor"
        placeholder="Enter email address"
      />
      <div
        v-if="isChecking"
        class="absolute right-10 top-1/2 -translate-y-1/2"
      >
        <UIcon
          name="i-heroicons-arrow-path-20-solid"
          class="animate-spin"
        />
      </div>
    </div>
    <p
      v-if="emailExists"
      class="mt-1 text-sm text-red-500"
    >
      This email is already registered, please use another.
    </p>
  </UFormGroup>
</template>

<script setup>
import debounce from "lodash/debounce";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  originalEmail: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue", "validation-change"]);

const email = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const emailError = ref("");
const emailExists = ref(false);
const isChecking = ref(false);

const inputColor = computed(() => {
  if (emailError.value) return "red";
  if (emailExists.value) return "red";
  if (email.value && !emailExists.value && !isChecking.value) return "green";
  return undefined;
});

const getTrailingIcon = computed(() => {
  if (emailError.value || emailExists.value)
    return "i-heroicons-exclamation-triangle-20-solid";
  if (email.value && !emailExists.value && !isChecking.value)
    return "i-heroicons-check-circle-20-solid";
  return undefined;
});

const validateEmail = () => {
  if (!email.value || email.value.trim() === "") {
    emailError.value = "You must enter an email";
    emit("validation-change", false);
    return false;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email.value)) {
    emailError.value = "Please enter a valid email address";
    emit("validation-change", false);
    return false;
  }

  emailError.value = "";
  return true;
};

const checkEmail = async () => {
  if (!email.value || !validateEmail()) return;
  if (email.value === props.originalEmail) {
    emailExists.value = false;
    emailError.value = "";
    emit("validation-change", true);
    return;
  }

  isChecking.value = true;
  emailExists.value = false;

  try {
    const response = await useApiFetch("check-user-email", {
      params: { email: email.value },
    });

    emailExists.value = response.exists;
    emit("validation-change", !emailExists.value);
  } catch (error) {
    console.error("Error checking email:", error);
    emailError.value = "Error checking email";
  } finally {
    isChecking.value = false;
  }
};

const debounceCheckEmail = debounce(() => {
  checkEmail();
}, 500);

onBeforeUnmount(() => {
  debounceCheckEmail.cancel();
});
</script>
